import React from "react";
import Header from "../components/header";
import Iframe from "react-iframe";
import "../styles/styles.css";
const NewsLetter = ({ pageContext }) => {
  return (
    <div>
      <Header back="true" taal={pageContext.langKey} />
      <div>
        <div className="textContent">
          <Iframe
            url={"https://www.circusronaldo.be/nieuwsbrief_m_nl.html"}
            width="100%"
            height="800px"
            id="myId"
            display="initial"
            position="relative"
          />
        </div>
      </div>
    </div>
  );
};
export default NewsLetter;
